export default `<header class="hero flex bg-cover" id="top">
    <div class="wrapper cf">
      <hgroup class="hero__headings flex">
        <h1 class="title device">Suscripción ViX</h1>
        <strong class="claim">¿Quieres vivir historias únicas y los mejores partidos de la Liga MX?</strong>
        <div class="hero__planes">
          <p class="explanation">Únete para disfrutar del mejor contenido de ViX</p>
          <div class="row">
            <div class="hero__plan">
              <div class="hero__plan-list">
                <h2 class="hero__plan-logo"><img src="https://suscripcion.atresplayer.com/vix/img/vix-atresplayer.png" alt="plan VIX | atresplayer" /></h2>
                <p>Más de 20.000 horas de contenido de ViX con series, películas, telenovelas y contenidos originales disponibles sin publicidad y con la mejor calidad. Incluye también los mejores partidos de la Liga MX de fútbol mexicano.</p>
              </div>
              <div class="hero__plan-precios">
                <a class="precio" href="https://atresplayer.com/registro?additionalData=eyJvcmlnaW5MYW5kaW5nIjoiaHR0cHM6Ly9ub3ZlbGFzLW5vdmEuYXRyZXNwbGF5ZXIuY29tL2hhenRlLXByZW1pdW0vcHJpbWVyL2luZGV4Lmh0bWwiLCJ0eXBlT2ZFbnRyeVBvaW50IjoibGFuZGluZyIsInNldFVwSXRlbXNUb0NhcnQiOmZhbHNlLCJwYWNrYWdlSWQiOjE3MDY5ODZ9">
                  <span>3,99€<span class="alt">/mes</span></span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </hgroup>
	  <span class="hero__scroll bg-cover"></span>
    </div>
  </header>
  <section class="content">
    <div class="wrapper cf">
      <article class="content__item flex">
        <figure class="content__item-image">
          <img src="https://suscripcion.atresplayer.com/vix/img/seleccion.png" alt="El mejor catálogo de telenovelas">
        </figure>
        <hgroup class="content__item__info flex parallax" data-paroller-factor="0.1" data-paroller-type="foreground" data-paroller-direction="vertical">
          <h3 class="title primary-color">El mejor catálogo de telenovelas</h3>
          <p class="text">Amor, pasión y deseo. Una selección inédita en España y con más de 20.000 horas de los mejores títulos que estabas esperando.</p>
          <a class="button" href="https://api.atresplayer.com/login?redirect=https%3A%2F%2Fwww.atresplayer.com%2Fusuario%2Fsuscripcion%2Fpaquetes%2Fpaquete%2F1706986%3FrollbackFunnelsParams%3DeyJmdW5uZWxzIjp7ImNvbW1vbnNWYXJzIjp7InR5cGVQcm9jZXNzIjoic3Vic2NyaXBjaW9uIiwiZnVubmVsIjp7InN0ZXAiOjAsImRlc2NyaXB0aW9uIjoic3Vic2NyaXBjaW9uIn0sInN0YXR1cyI6ImluaWNpbyIsImVudHJ5UG9pbnQiOiJib3RvbmxhbmRpbmcifSwidHJhY2tMb2dpbiI6dHJ1ZSwidHJhY2tTdWJzY3JpcHRpb24iOnRydWV9LCJwb3N0cGF5bWVudCI6Imh0dHBzOi8vd3d3LmF0cmVzcGxheWVyLmNvbS9ub3ZlbGFzLW5vdmEvIn0%3D&additionalData=eyJmdW5uZWxzIjp7ImNvbW1vbnNWYXJzIjp7InR5cGVQcm9jZXNzIjoic3Vic2NyaXBjaW9uIiwiZnVubmVsIjp7InN0ZXAiOjAsImRlc2NyaXB0aW9uIjoic3Vic2NyaXBjaW9uIn0sInN0YXR1cyI6ImluaWNpbyIsImVudHJ5UG9pbnQiOiJib3RvbmxhbmRpbmcifSwidHJhY2tMb2dpbiI6dHJ1ZSwidHJhY2tTdWJzY3JpcHRpb24iOnRydWV9LCJwcm9tb1NpZ251cCI6InRlbGVub3ZlbGFzIiwic2tpcEFjdGl2YXRpb24iOnRydWUsInBvc3RwYXltZW50IjoiaHR0cHM6Ly93d3cuYXRyZXNwbGF5ZXIuY29tLyJ9==">HISTORIAS ÚNICAS</a>
        </hgroup>
        <img src="https://suscripcion.atresplayer.com/vix/img/section-bg-1.jpg" class="content-bg">
      </article>
      <article class="content__item flex">
        <figure class="content__item-image light-gradient">
          <img src="https://suscripcion.atresplayer.com/vix/img/originales.png" alt="Originales de ViX">
        </figure>
        <hgroup class="content__item__info flex parallax" data-paroller-factor="0.1" data-paroller-type="foreground" data-paroller-direction="vertical">
          <h3 class="title primary-color">Originales de ViX</h3>
          <p class="text">Las mejores series, películas y documentales del catálogo de ViX.</p>
          <a class="button" href="https://api.atresplayer.com/login?redirect=https%3A%2F%2Fwww.atresplayer.com%2Fusuario%2Fsuscripcion%2Fpaquetes%2Fpaquete%2F1706986%3FrollbackFunnelsParams%3DeyJmdW5uZWxzIjp7ImNvbW1vbnNWYXJzIjp7InR5cGVQcm9jZXNzIjoic3Vic2NyaXBjaW9uIiwiZnVubmVsIjp7InN0ZXAiOjAsImRlc2NyaXB0aW9uIjoic3Vic2NyaXBjaW9uIn0sInN0YXR1cyI6ImluaWNpbyIsImVudHJ5UG9pbnQiOiJib3RvbmxhbmRpbmcifSwidHJhY2tMb2dpbiI6dHJ1ZSwidHJhY2tTdWJzY3JpcHRpb24iOnRydWV9LCJwb3N0cGF5bWVudCI6Imh0dHBzOi8vd3d3LmF0cmVzcGxheWVyLmNvbS9ub3ZlbGFzLW5vdmEvIn0%3D&additionalData=eyJmdW5uZWxzIjp7ImNvbW1vbnNWYXJzIjp7InR5cGVQcm9jZXNzIjoic3Vic2NyaXBjaW9uIiwiZnVubmVsIjp7InN0ZXAiOjAsImRlc2NyaXB0aW9uIjoic3Vic2NyaXBjaW9uIn0sInN0YXR1cyI6ImluaWNpbyIsImVudHJ5UG9pbnQiOiJib3RvbmxhbmRpbmcifSwidHJhY2tMb2dpbiI6dHJ1ZSwidHJhY2tTdWJzY3JpcHRpb24iOnRydWV9LCJwcm9tb1NpZ251cCI6InRlbGVub3ZlbGFzIiwic2tpcEFjdGl2YXRpb24iOnRydWUsInBvc3RwYXltZW50IjoiaHR0cHM6Ly93d3cuYXRyZXNwbGF5ZXIuY29tLyJ9==">SOLO PARA TI</a>
        </hgroup>
        <img src="https://suscripcion.atresplayer.com/vix/img/section-bg-2.jpg" class="content-bg">
      </article>
      <!-- item -->
      <article class="content__item flex">
        <figure class="content__item-image light-gradient">
          <img src="https://suscripcion.atresplayer.com/vix/img/cine.png" alt="El Cine a tu alcance">
        </figure>
        <hgroup class="content__item__info flex parallax" data-paroller-factor="0.1" data-paroller-type="foreground" data-paroller-direction="vertical">
          <h3 class="title primary-color">El Cine a tu alcance</h3>
          <p class="text">La mejor selección de películas para todo tipo de públicos.</p>
          <a class="button" href="https://api.atresplayer.com/login?redirect=https%3A%2F%2Fwww.atresplayer.com%2Fusuario%2Fsuscripcion%2Fpaquetes%2Fpaquete%2F1706986%3FrollbackFunnelsParams%3DeyJmdW5uZWxzIjp7ImNvbW1vbnNWYXJzIjp7InR5cGVQcm9jZXNzIjoic3Vic2NyaXBjaW9uIiwiZnVubmVsIjp7InN0ZXAiOjAsImRlc2NyaXB0aW9uIjoic3Vic2NyaXBjaW9uIn0sInN0YXR1cyI6ImluaWNpbyIsImVudHJ5UG9pbnQiOiJib3RvbmxhbmRpbmcifSwidHJhY2tMb2dpbiI6dHJ1ZSwidHJhY2tTdWJzY3JpcHRpb24iOnRydWV9LCJwb3N0cGF5bWVudCI6Imh0dHBzOi8vd3d3LmF0cmVzcGxheWVyLmNvbS9ub3ZlbGFzLW5vdmEvIn0%3D&additionalData=eyJmdW5uZWxzIjp7ImNvbW1vbnNWYXJzIjp7InR5cGVQcm9jZXNzIjoic3Vic2NyaXBjaW9uIiwiZnVubmVsIjp7InN0ZXAiOjAsImRlc2NyaXB0aW9uIjoic3Vic2NyaXBjaW9uIn0sInN0YXR1cyI6ImluaWNpbyIsImVudHJ5UG9pbnQiOiJib3RvbmxhbmRpbmcifSwidHJhY2tMb2dpbiI6dHJ1ZSwidHJhY2tTdWJzY3JpcHRpb24iOnRydWV9LCJwcm9tb1NpZ251cCI6InRlbGVub3ZlbGFzIiwic2tpcEFjdGl2YXRpb24iOnRydWUsInBvc3RwYXltZW50IjoiaHR0cHM6Ly93d3cuYXRyZXNwbGF5ZXIuY29tLyJ9==">PREPARA PALOMITAS</a>
        </hgroup>
        <img src="https://suscripcion.atresplayer.com/vix/img/section-bg-3.jpg" class="content-bg">
      </article>
      <!-- item -->
      <article class="content__item flex">
        <figure class="content__item-image light-gradient">
          <img src="https://suscripcion.atresplayer.com/vix/img/liga-mx.png" alt="Lo mejor de la Liga MX">
          <span class="luz alt"></span>
        </figure>
        <hgroup class="content__item__info flex parallax" data-paroller-factor="0.1" data-paroller-type="foreground" data-paroller-direction="vertical">
          <h3 class="title primary-color">Lo mejor de la Liga MX</h3>
          <p class="text">No te pierdas los partidos de los equipos más importantes de la Liga Mexicana.</p>
          <a class="button" href="https://api.atresplayer.com/login?redirect=https%3A%2F%2Fwww.atresplayer.com%2Fusuario%2Fsuscripcion%2Fpaquetes%2Fpaquete%2F1706986%3FrollbackFunnelsParams%3DeyJmdW5uZWxzIjp7ImNvbW1vbnNWYXJzIjp7InR5cGVQcm9jZXNzIjoic3Vic2NyaXBjaW9uIiwiZnVubmVsIjp7InN0ZXAiOjAsImRlc2NyaXB0aW9uIjoic3Vic2NyaXBjaW9uIn0sInN0YXR1cyI6ImluaWNpbyIsImVudHJ5UG9pbnQiOiJib3RvbmxhbmRpbmcifSwidHJhY2tMb2dpbiI6dHJ1ZSwidHJhY2tTdWJzY3JpcHRpb24iOnRydWV9LCJwb3N0cGF5bWVudCI6Imh0dHBzOi8vd3d3LmF0cmVzcGxheWVyLmNvbS9ub3ZlbGFzLW5vdmEvIn0%3D&additionalData=eyJmdW5uZWxzIjp7ImNvbW1vbnNWYXJzIjp7InR5cGVQcm9jZXNzIjoic3Vic2NyaXBjaW9uIiwiZnVubmVsIjp7InN0ZXAiOjAsImRlc2NyaXB0aW9uIjoic3Vic2NyaXBjaW9uIn0sInN0YXR1cyI6ImluaWNpbyIsImVudHJ5UG9pbnQiOiJib3RvbmxhbmRpbmcifSwidHJhY2tMb2dpbiI6dHJ1ZSwidHJhY2tTdWJzY3JpcHRpb24iOnRydWV9LCJwcm9tb1NpZ251cCI6InRlbGVub3ZlbGFzIiwic2tpcEFjdGl2YXRpb24iOnRydWUsInBvc3RwYXltZW50IjoiaHR0cHM6Ly93d3cuYXRyZXNwbGF5ZXIuY29tLyJ9==">TODO EL FÚTBOL</a>
        </hgroup>
        <img src="https://suscripcion.atresplayer.com/vix/img/section-bg-4.jpg" class="content-bg">
      </article>
      <!-- item -->
      <article class="content__item flex">
        <figure class="content__item-image light-gradient">
          <img src="https://suscripcion.atresplayer.com/vix/img/exclusiva.png" alt="En exclusiva en España">
        </figure>
        <hgroup class="content__item__info flex parallax" data-paroller-factor="0.1" data-paroller-type="foreground" data-paroller-direction="vertical">
          <h3 class="title primary-color">En exclusiva en España</h3>
          <p class="text">Disfruta de contenido de ViX disponible a través de atresplayer.</p>
          <a class="button" href="https://api.atresplayer.com/login?redirect=https%3A%2F%2Fwww.atresplayer.com%2Fusuario%2Fsuscripcion%2Fpaquetes%2Fpaquete%2F1706986%3FrollbackFunnelsParams%3DeyJmdW5uZWxzIjp7ImNvbW1vbnNWYXJzIjp7InR5cGVQcm9jZXNzIjoic3Vic2NyaXBjaW9uIiwiZnVubmVsIjp7InN0ZXAiOjAsImRlc2NyaXB0aW9uIjoic3Vic2NyaXBjaW9uIn0sInN0YXR1cyI6ImluaWNpbyIsImVudHJ5UG9pbnQiOiJib3RvbmxhbmRpbmcifSwidHJhY2tMb2dpbiI6dHJ1ZSwidHJhY2tTdWJzY3JpcHRpb24iOnRydWV9LCJwb3N0cGF5bWVudCI6Imh0dHBzOi8vd3d3LmF0cmVzcGxheWVyLmNvbS9ub3ZlbGFzLW5vdmEvIn0%3D&additionalData=eyJmdW5uZWxzIjp7ImNvbW1vbnNWYXJzIjp7InR5cGVQcm9jZXNzIjoic3Vic2NyaXBjaW9uIiwiZnVubmVsIjp7InN0ZXAiOjAsImRlc2NyaXB0aW9uIjoic3Vic2NyaXBjaW9uIn0sInN0YXR1cyI6ImluaWNpbyIsImVudHJ5UG9pbnQiOiJib3RvbmxhbmRpbmcifSwidHJhY2tMb2dpbiI6dHJ1ZSwidHJhY2tTdWJzY3JpcHRpb24iOnRydWV9LCJwcm9tb1NpZ251cCI6InRlbGVub3ZlbGFzIiwic2tpcEFjdGl2YXRpb24iOnRydWUsInBvc3RwYXltZW50IjoiaHR0cHM6Ly93d3cuYXRyZXNwbGF5ZXIuY29tLyJ9==">LO QUIERO</a>
        </hgroup>
        <img src="https://suscripcion.atresplayer.com/vix/img/section-bg-5.jpg" class="content-bg">
      </article>
      <!-- item -->
      <article class="content__item flex">
        <figure class="content__item-image light-gradient">
          <img src="https://suscripcion.atresplayer.com/vix/img/multipantalla.png" alt="Multipantalla">
        </figure>
        <hgroup class="content__item__info flex parallax" data-paroller-factor="0.1" data-paroller-type="foreground" data-paroller-direction="vertical">
          <h3 class="title primary-color">Multipantalla</h3>
          <p class="text">Nuestro contenido como tú quieras, cuando quieras y en el dispositivo que quieras.</p>
          <a class="button" href="https://api.atresplayer.com/login?redirect=https%3A%2F%2Fwww.atresplayer.com%2Fusuario%2Fsuscripcion%2Fpaquetes%2Fpaquete%2F1706986%3FrollbackFunnelsParams%3DeyJmdW5uZWxzIjp7ImNvbW1vbnNWYXJzIjp7InR5cGVQcm9jZXNzIjoic3Vic2NyaXBjaW9uIiwiZnVubmVsIjp7InN0ZXAiOjAsImRlc2NyaXB0aW9uIjoic3Vic2NyaXBjaW9uIn0sInN0YXR1cyI6ImluaWNpbyIsImVudHJ5UG9pbnQiOiJib3RvbmxhbmRpbmcifSwidHJhY2tMb2dpbiI6dHJ1ZSwidHJhY2tTdWJzY3JpcHRpb24iOnRydWV9LCJwb3N0cGF5bWVudCI6Imh0dHBzOi8vd3d3LmF0cmVzcGxheWVyLmNvbS9ub3ZlbGFzLW5vdmEvIn0%3D&additionalData=eyJmdW5uZWxzIjp7ImNvbW1vbnNWYXJzIjp7InR5cGVQcm9jZXNzIjoic3Vic2NyaXBjaW9uIiwiZnVubmVsIjp7InN0ZXAiOjAsImRlc2NyaXB0aW9uIjoic3Vic2NyaXBjaW9uIn0sInN0YXR1cyI6ImluaWNpbyIsImVudHJ5UG9pbnQiOiJib3RvbmxhbmRpbmcifSwidHJhY2tMb2dpbiI6dHJ1ZSwidHJhY2tTdWJzY3JpcHRpb24iOnRydWV9LCJwcm9tb1NpZ251cCI6InRlbGVub3ZlbGFzIiwic2tpcEFjdGl2YXRpb24iOnRydWUsInBvc3RwYXltZW50IjoiaHR0cHM6Ly93d3cuYXRyZXNwbGF5ZXIuY29tLyJ9==">A MI GUSTO</a>
        </hgroup>
        <img src="https://suscripcion.atresplayer.com/vix/img/section-bg-6.jpg" class="content-bg">
      </article>
      <!-- item -->
      <article class="content__item flex">
        <figure class="content__item-image light-gradient">
          <img src="https://suscripcion.atresplayer.com/vix/img/sinpublicidad.png" alt="Sin interrupciones y la máxima calidad">
        </figure>
        <hgroup class="content__item__info flex parallax" data-paroller-factor="0.1" data-paroller-type="foreground" data-paroller-direction="vertical">
          <h3 class="title primary-color">Sin interrupciones y la máxima calidad</h3>
          <p class="text">Consume todos tus capítulos a tu gusto, sin cortes y con la mejor calidad de imagen HD-4K.</p>
          <a class="button" href="https://api.atresplayer.com/login?redirect=https%3A%2F%2Fwww.atresplayer.com%2Fusuario%2Fsuscripcion%2Fpaquetes%2Fpaquete%2F1706986%3FrollbackFunnelsParams%3DeyJmdW5uZWxzIjp7ImNvbW1vbnNWYXJzIjp7InR5cGVQcm9jZXNzIjoic3Vic2NyaXBjaW9uIiwiZnVubmVsIjp7InN0ZXAiOjAsImRlc2NyaXB0aW9uIjoic3Vic2NyaXBjaW9uIn0sInN0YXR1cyI6ImluaWNpbyIsImVudHJ5UG9pbnQiOiJib3RvbmxhbmRpbmcifSwidHJhY2tMb2dpbiI6dHJ1ZSwidHJhY2tTdWJzY3JpcHRpb24iOnRydWV9LCJwb3N0cGF5bWVudCI6Imh0dHBzOi8vd3d3LmF0cmVzcGxheWVyLmNvbS9ub3ZlbGFzLW5vdmEvIn0%3D&additionalData=eyJmdW5uZWxzIjp7ImNvbW1vbnNWYXJzIjp7InR5cGVQcm9jZXNzIjoic3Vic2NyaXBjaW9uIiwiZnVubmVsIjp7InN0ZXAiOjAsImRlc2NyaXB0aW9uIjoic3Vic2NyaXBjaW9uIn0sInN0YXR1cyI6ImluaWNpbyIsImVudHJ5UG9pbnQiOiJib3RvbmxhbmRpbmcifSwidHJhY2tMb2dpbiI6dHJ1ZSwidHJhY2tTdWJzY3JpcHRpb24iOnRydWV9LCJwcm9tb1NpZ251cCI6InRlbGVub3ZlbGFzIiwic2tpcEFjdGl2YXRpb24iOnRydWUsInBvc3RwYXltZW50IjoiaHR0cHM6Ly93d3cuYXRyZXNwbGF5ZXIuY29tLyJ9==">SIN COMPROMISO</a>
        </hgroup>
        <img src="https://suscripcion.atresplayer.com/vix/img/section-bg-7.jpg" class="content-bg">
      </article>
    </div>
  </section>
  <section class="bottom__call">
    <div class="wrapper">
      <article class="box flex">
        <span class="hero__atp-logo bg-cover">
          <img src="https://suscripcion.atresplayer.com/vix/img/atresplayer-nova-logo.png" />
        </span>
        <h2 class="title">"Las historias más trepidantes y <span>el mejor fútbol de la Liga MX"</span></h2>
        <a class="button" href="https://api.atresplayer.com/login?redirect=https%3A%2F%2Fwww.atresplayer.com%2Fusuario%2Fsuscripcion%2Fpaquetes%2Fpaquete%2F1706986%3FrollbackFunnelsParams%3DeyJmdW5uZWxzIjp7ImNvbW1vbnNWYXJzIjp7InR5cGVQcm9jZXNzIjoic3Vic2NyaXBjaW9uIiwiZnVubmVsIjp7InN0ZXAiOjAsImRlc2NyaXB0aW9uIjoic3Vic2NyaXBjaW9uIn0sInN0YXR1cyI6ImluaWNpbyIsImVudHJ5UG9pbnQiOiJib3RvbmxhbmRpbmcifSwidHJhY2tMb2dpbiI6dHJ1ZSwidHJhY2tTdWJzY3JpcHRpb24iOnRydWV9LCJwb3N0cGF5bWVudCI6Imh0dHBzOi8vd3d3LmF0cmVzcGxheWVyLmNvbS9ub3ZlbGFzLW5vdmEvIn0%3D&additionalData=eyJmdW5uZWxzIjp7ImNvbW1vbnNWYXJzIjp7InR5cGVQcm9jZXNzIjoic3Vic2NyaXBjaW9uIiwiZnVubmVsIjp7InN0ZXAiOjAsImRlc2NyaXB0aW9uIjoic3Vic2NyaXBjaW9uIn0sInN0YXR1cyI6ImluaWNpbyIsImVudHJ5UG9pbnQiOiJib3RvbmxhbmRpbmcifSwidHJhY2tMb2dpbiI6dHJ1ZSwidHJhY2tTdWJzY3JpcHRpb24iOnRydWV9LCJwcm9tb1NpZ251cCI6InRlbGVub3ZlbGFzIiwic2tpcEFjdGl2YXRpb24iOnRydWUsInBvc3RwYXltZW50IjoiaHR0cHM6Ly93d3cuYXRyZXNwbGF5ZXIuY29tLyJ9==">CONTRATA AHORA</a>
      </article>
    </div>
  </section>
  <footer class="foo flex">
    <div class="wrapper cf">
      <p class="creds">© Atresmedia Corporación de Medios de Comunicación, S.A - A. Isla Graciosa 13, 28703, S.S. de los Reyes, Madrid. Reservados todos los derechos</p>
    </div>
  </footer>`;
