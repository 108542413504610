export default `<span class="bannerTop flex">
    <p>¿ERES NUEV@? SUSCRÍBETE YA Y DISFRUTA DE UN <span>20% DE DESCUENTO</span> EN EL PLAN ANUAL. USA CÓDIGO <span>1FX3P</span></p>
  </span>
  <header class="hero flex bg-cover">
    <div class="wrapper cf">
      <hgroup class="hero__headings flex">
        <h1 class="hero__atp-logo bg-cover" alt="atresplayer plan internacional">
          <img src="https://suscripcion.atresplayer.com/hazte-premium/internacional/usa/img/atresplayer-logo.svg" alt="atresplayer plan internacional" />
        </h1>
        <p class="title">Canales de TV, series y programas en streaming. ¡Estrenos <span>todos los días</span>!</p>
        <div class="hero__planes">
          <p class="explanation">Contrátalo ya desde cualquier país</p>
          <div class="row">
            <!-- plan -->
            <div class="hero__plan familiar">
              <div class="hero__plan-list">
                <h2 class="hero__plan-logo">PLAN<span>internacional</span></h2>
                <ul class="hero__list">
                  <li>Originales y exclusivos</li>
                  <li>Preestrenos</li>
                  <li>Subtítulos en español</li>
                  <li>3 perfiles</li>
                  <li>Sin publicidad</li>
                  <li>Descarga offline</li>
                  <li>Calidad 4K</li>
                </ul>
              </div>
              <div class="hero__plan-precios">
                <span class="precio">
                  <span class="pre">Mensual</span>
                  <span>$5.99</span>
                </span>
                <span class="precio">
                  <span class="pre">Anual</span>
                  <span>$59.99</span>
                  <span class="add">ahorra 2 meses</span>
                </span>
              </div>
            </div>
          </div>
          <a class="button" href="https://www.atresplayer.com/registro?additionalData=eyJvcmlnaW5MYW5kaW5nIjoiaHR0cHM6Ly9zdXNjcmlwY2lvbi5hdHJlc3BsYXllci5jb20vaGF6dGUtcHJlbWl1bS9pbnRlcm5hY2lvbmFsL2V4cGF0LyIsInR5cGVPZkVudHJ5UG9pbnQiOiJsYW5kaW5nIiwicGFja2FnZUlkIjo4NzU1NjB9">Suscríbete ya</a>
        </div>
        
      </hgroup>
	  
	  <span class="hero__scroll bg-cover"></span>
    </div>
  </header>
  <section class="content">
    <div class="wrapper cf">
      <ul class="content__features">
        <li>
          <a href="#originales">
            <img src="https://suscripcion.atresplayer.com/hazte-premium/internacional/usa/img/features/originales.svg" alt="Originales y exclusivos"/>
            <p>Originales y exclusivos</p>
          </a>
        </li>
        <li>
          <a href="#subtitulos">
            <img src="https://suscripcion.atresplayer.com/hazte-premium/internacional/usa/img/features/subtitulos.svg" alt="Subtítulos"/>
            <p>Subtítulos en español</p>
          </a>
        </li>
        <li>
          <a href="#catalogo">
            <img src="https://suscripcion.atresplayer.com/hazte-premium/internacional/usa/img/features/preestrenos.svg" alt="Preestrenos"/>
            <p>Preestrenos</p>
          </a>
        </li>
        <li>
          <a href="#3perfiles">
            <img src="https://suscripcion.atresplayer.com/hazte-premium/internacional/usa/img/features/3perfiles.svg" alt="3 perfiles"/>
            <p>3 perfiles</p>
          </a>
        </li>
        <li>
          <a href="#sinpubli">
            <img src="https://suscripcion.atresplayer.com/hazte-premium/internacional/usa/img/features/sinpublicidad.svg" alt="Sin publicidad"/>
            <p>Sin publicidad</p>
          </a>
        </li>
        <li>
          <a href="#4k">
            <img src="https://suscripcion.atresplayer.com/hazte-premium/internacional/usa/img/features/calidad4k.svg" alt="Calidad 4K"/>
            <p>Calidad 4K</p>
          </a>
        </li> 
        <li>
          <a href="#offline">
            <img src="https://suscripcion.atresplayer.com/hazte-premium/internacional/usa/img/features/descargaoffline.svg" alt="Descarga offline"/>
            <p>Descarga offline</p>
          </a>
        </li> 
      </ul>
    </div>  
    <div class="wrapper cf">
      <article id="originales" class="content__item flex">
        <figure class="content__item-image">
          <img src="https://suscripcion.atresplayer.com/hazte-premium/internacional/usa/img/originales.png" alt="Originales y exclusivos">
          <span class="feature-arrow">
            <img src="https://suscripcion.atresplayer.com/hazte-premium/internacional/usa/img/features/clean/originales.svg">
          </span>
        </figure>
        <hgroup class="content__item__info flex parallax" data-paroller-factor="0.1" data-paroller-type="foreground" data-paroller-direction="vertical">
          <h3 class="title primary-color">Contenido original y exclusivo</h3>
          <p class="text">Accede a los mejores contenidos originales y exclusivos solo disponibles en atresplayer.</p>
          <a class="button" href="https://www.atresplayer.com/registro?additionalData=eyJvcmlnaW5MYW5kaW5nIjoiaHR0cHM6Ly9zdXNjcmlwY2lvbi5hdHJlc3BsYXllci5jb20vaGF6dGUtcHJlbWl1bS9pbnRlcm5hY2lvbmFsL2V4cGF0LyIsInR5cGVPZkVudHJ5UG9pbnQiOiJsYW5kaW5nIiwicGFja2FnZUlkIjo4NzU1NjB9">Suscríbete ya</a>
        </hgroup>
        <img src="https://suscripcion.atresplayer.com/hazte-premium/internacional/usa/img/section-bg-1.jpg" class="content-bg">
      </article>
	  <article id="preestrenos" class="content__item flex">
        <figure class="content__item-image">
          <img src="https://suscripcion.atresplayer.com/hazte-premium/internacional/usa/img/preestrenos.png" alt="Preestrenos">
          <span class="feature-arrow">
            <img src="https://suscripcion.atresplayer.com/hazte-premium/internacional/usa/img/features/clean/preestrenos.svg">
          </span>
        </figure>
        <hgroup class="content__item__info flex parallax" data-paroller-factor="0.1" data-paroller-type="foreground" data-paroller-direction="vertical">
          <h3 class="title primary-color">Preestrenos</h3>
          <p class="text">Adelántate a la emisión en televisión de tus series, programas y telenovelas favoritos con los preestrenos exclusivos premium.</p>
          <a class="button" href="https://www.atresplayer.com/registro?additionalData=eyJvcmlnaW5MYW5kaW5nIjoiaHR0cHM6Ly9zdXNjcmlwY2lvbi5hdHJlc3BsYXllci5jb20vaGF6dGUtcHJlbWl1bS9pbnRlcm5hY2lvbmFsL2V4cGF0LyIsInR5cGVPZkVudHJ5UG9pbnQiOiJsYW5kaW5nIiwicGFja2FnZUlkIjo4NzU1NjB9">Todos los preestrenos</a>
        </hgroup>
        <img src="https://suscripcion.atresplayer.com/hazte-premium/internacional/usa/img/section-bg-3.jpg" class="content-bg">
      </article>
      <article id="catalogo" class="content__item flex">
        <figure class="content__item-image">
          <img src="https://suscripcion.atresplayer.com/hazte-premium/internacional/usa/img/catalogo.png" alt="Todo el catálogo">
          <span class="feature-arrow">
            <img src="https://suscripcion.atresplayer.com/hazte-premium/internacional/usa/img/features/clean/catalogo.svg">
          </span>
        </figure>
        <hgroup class="content__item__info flex parallax" data-paroller-factor="0.1" data-paroller-type="foreground" data-paroller-direction="vertical">
          <h3 class="title primary-color">Todo el catálogo</h3>
          <p class="text">Disfruta de todo el contenido de atresplayer en streaming y bajo demanda.</p>
          <a class="button" href="https://api.atresplayer.com/login?redirect=https%3A%2F%2Fwww.atresplayer.com%2Fusuario%2Fsuscripcion%2Fpaquetes%2Fpaquete%2F862777%3FrollbackFunnelsParams%3DeyJmdW5uZWxzIjp7ImNvbW1vbnNWYXJzIjp7InR5cGVQcm9jZXNzIjoic3Vic2NyaXBjaW9uIiwiZnVubmVsIjp7InN0ZXAiOjAsImRlc2NyaXB0aW9uIjoic3Vic2NyaXBjaW9uIn0sInN0YXR1cyI6ImluaWNpbyIsImVudHJ5UG9pbnQiOiJib3RvbmxhbmRpbmcifSwidHJhY2tMb2dpbiI6dHJ1ZSwidHJhY2tTdWJzY3JpcHRpb24iOnRydWV9LCJwb3N0cGF5bWVudCI6Imh0dHBzOi8vd3d3LmF0cmVzcGxheWVyLmNvbS8ifQ==&additionalData=eyJmdW5uZWxzIjp7ImNvbW1vbnNWYXJzIjp7InR5cGVQcm9jZXNzIjoic3Vic2NyaXBjaW9uIiwiZnVubmVsIjp7InN0ZXAiOjAsImRlc2NyaXB0aW9uIjoic3Vic2NyaXBjaW9uIn0sInN0YXR1cyI6ImluaWNpbyIsImVudHJ5UG9pbnQiOiJib3RvbmxhbmRpbmcifSwidHJhY2tMb2dpbiI6dHJ1ZSwidHJhY2tTdWJzY3JpcHRpb24iOnRydWV9LCJwcm9tb1NpZ251cCI6InByZW1pdW0iLCJza2lwQWN0aXZhdGlvbiI6dHJ1ZSwicG9zdHBheW1lbnQiOiJodHRwczovL3d3dy5hdHJlc3BsYXllci5jb20vIn0==">SUSRÍBETE YA</a>
        </hgroup>
        <img src="https://suscripcion.atresplayer.com/hazte-premium/internacional/usa/img/section-bg-3.jpg" class="content-bg">
      </article>
      <article id="subtitulos" class="content__item flex">
        <figure class="content__item-image">
          <img src="https://suscripcion.atresplayer.com/hazte-premium/internacional/usa/img/subtitulos.png" alt="subtítulos">
          <span class="feature-arrow">
            <img src="https://suscripcion.atresplayer.com/hazte-premium/internacional/usa/img/features/clean/subtitulos.svg">
          </span>
        </figure>
        <hgroup class="content__item__info flex parallax" data-paroller-factor="0.1" data-paroller-type="foreground" data-paroller-direction="vertical">
          <h3 class="title primary-color">Sin fronteras</h3>
          <p class="text">Con el plan internacional, disfruta de todo el contenido de atresplayer desde cualquier país.</p>
          <a class="button" href="https://www.atresplayer.com/registro?additionalData=eyJvcmlnaW5MYW5kaW5nIjoiaHR0cHM6Ly9zdXNjcmlwY2lvbi5hdHJlc3BsYXllci5jb20vaGF6dGUtcHJlbWl1bS9pbnRlcm5hY2lvbmFsL2V4cGF0LyIsInR5cGVPZkVudHJ5UG9pbnQiOiJsYW5kaW5nIiwicGFja2FnZUlkIjo4NzU1NjB9">Contrata ahora</a>
        </hgroup>
        <img src="https://suscripcion.atresplayer.com/hazte-premium/internacional/usa/img/section-bg-2.jpg" class="content-bg">
      </article>
      <article id="3perfiles" class="content__item familiar flex">
        <figure class="content__item-image">
          <img src="https://suscripcion.atresplayer.com/hazte-premium/internacional/usa/img/3perfiles.png" alt="3 perfiles">
          <span class="feature-arrow">
            <img src="https://suscripcion.atresplayer.com/hazte-premium/internacional/usa/img/features/clean/3perfiles.svg">
          </span>
          <span class="luz"></span>
        </figure> 
        <hgroup class="content__item__info flex parallax" data-paroller-factor="0.1" data-paroller-type="foreground" data-paroller-direction="vertical">
          <!--<span class="solo-internacional"></span>-->
          <h3 class="title primary-color">3 perfiles</h3>
          <p class="text">Puedes tener hasta 3 perfiles individuales con recomendaciones basadas en las preferencias de cada usuario.</p>
          <a class="button" href="https://www.atresplayer.com/registro?additionalData=eyJvcmlnaW5MYW5kaW5nIjoiaHR0cHM6Ly9zdXNjcmlwY2lvbi5hdHJlc3BsYXllci5jb20vaGF6dGUtcHJlbWl1bS9pbnRlcm5hY2lvbmFsL2V4cGF0LyIsInR5cGVPZkVudHJ5UG9pbnQiOiJsYW5kaW5nIiwicGFja2FnZUlkIjo4NzU1NjB9">Comparte tu atresplayer</a>
        </hgroup>
        <img src="https://suscripcion.atresplayer.com/hazte-premium/internacional/usa/img/section-bg-2.jpg" class="content-bg">
      </article>
      <article id="sinpubli" class="content__item familiar flex">
        <figure class="content__item-image light-gradient">
          <img src="https://suscripcion.atresplayer.com/hazte-premium/internacional/usa/img/sin_publicidad.png" alt="Sin publicidad">
          <span class="feature-arrow">
            <img src="https://suscripcion.atresplayer.com/hazte-premium/internacional/usa/img/features/clean/sinpubli.svg">
          </span>
          <span class="luz"></span>
        </figure>
        <hgroup class="content__item__info flex parallax" data-paroller-factor="0.1" data-paroller-type="foreground" data-paroller-direction="vertical">
          <!--<span class="solo-internacional"></span>-->
          <h3 class="title primary-color">Sin publicidad</h3>
          <p class="text">Todo el contenido sin anuncios, solo tú decides cuando pausar una serie o programa e ir a por palomitas.</p>
          <a class="button" href="https://www.atresplayer.com/registro?additionalData=eyJvcmlnaW5MYW5kaW5nIjoiaHR0cHM6Ly9zdXNjcmlwY2lvbi5hdHJlc3BsYXllci5jb20vaGF6dGUtcHJlbWl1bS9pbnRlcm5hY2lvbmFsL2V4cGF0LyIsInR5cGVPZkVudHJ5UG9pbnQiOiJsYW5kaW5nIiwicGFja2FnZUlkIjo4NzU1NjB9">No más interrupciones</a>
        </hgroup>
        <img src="https://suscripcion.atresplayer.com/hazte-premium/internacional/usa/img/section-bg-1.jpg" class="content-bg">
      </article>
	   <article id="4k" class="content__item familiar flex">
        <figure class="content__item-image">
          <img src="https://suscripcion.atresplayer.com/hazte-premium/internacional/usa/img/4k.png" alt="Calidad 4K">
          <span class="feature-arrow">
            <img src="https://suscripcion.atresplayer.com/hazte-premium/internacional/usa/img/features/clean/4k.svg">
          </span>
          <span class="luz"></span>
        </figure> 
        <hgroup class="content__item__info flex parallax" data-paroller-factor="0.03" data-paroller-type="foreground" data-paroller-direction="vertical">
          <!--<span class="solo-internacional"></span>-->
          <h3 class="title primary-color">Calidad 4K</h3>
          <p class="text">Calidad HD-4K, el mejor sonido, VO y con subtítulos. Todo para que disfrutes como nunca de tu contenido favorito.</p>
          <a class="button" href="https://www.atresplayer.com/registro?additionalData=eyJvcmlnaW5MYW5kaW5nIjoiaHR0cHM6Ly9zdXNjcmlwY2lvbi5hdHJlc3BsYXllci5jb20vaGF6dGUtcHJlbWl1bS9pbnRlcm5hY2lvbmFsL2V4cGF0LyIsInR5cGVPZkVudHJ5UG9pbnQiOiJsYW5kaW5nIiwicGFja2FnZUlkIjo4NzU1NjB9">LA CALIDAD QUE TE MERECES</a>
        </hgroup>
        <img src="https://suscripcion.atresplayer.com/hazte-premium/internacional/usa/img/section-bg-4.jpg" class="content-bg"> 
      </article>
      <article id="offline" class="content__item familiar flex">
        <figure class="content__item-image no-gradient">
          <img src="https://suscripcion.atresplayer.com/hazte-premium/internacional/usa/img/offline.png" alt="Descarga offline">
          <span class="feature-arrow">
            <img src="https://suscripcion.atresplayer.com/hazte-premium/internacional/usa/img/features/clean/offline.svg">
          </span>
          <span class="luz"></span>
        </figure>
        <hgroup class="content__item__info flex parallax" data-paroller-factor="0.1" data-paroller-type="foreground" data-paroller-direction="vertical">
          <!--<span class="solo-internacional"></span>-->
          <h3 class="title primary-color">Descarga offline</h3>
          <p class="text">Las series y programas de Atresmedia donde quieras. Allá donde vayas, no dejes de ver lo que te gusta sin necesidad de conexión.</p>
          <a class="button" href="https://www.atresplayer.com/registro?additionalData=eyJvcmlnaW5MYW5kaW5nIjoiaHR0cHM6Ly9zdXNjcmlwY2lvbi5hdHJlc3BsYXllci5jb20vaGF6dGUtcHJlbWl1bS9pbnRlcm5hY2lvbmFsL2V4cGF0LyIsInR5cGVPZkVudHJ5UG9pbnQiOiJsYW5kaW5nIiwicGFja2FnZUlkIjo4NzU1NjB9">Donde y cuando quieras</a>
        </hgroup>
        <img src="https://suscripcion.atresplayer.com/hazte-premium/internacional/usa/img/section-bg-3.jpg" class="content-bg">
      </article>
    </div>
  </section>
  <section class="bottom__call">
    <div class="wrapper">
      <article class="box flex">
        <span class="hero__atp-logo bg-cover">
          <img src="https://suscripcion.atresplayer.com/hazte-premium/internacional/usa/img/atresplayer-logo.svg" />
        </span>
        <!--<span class="hero__scroll bg-cover"></span>-->
        <h2>Disfruta de los mejores contenidos <span>originales</span> y de tus series y programas <span>favoritos</span> en atresplayer</h2>
        <a class="button" href="https://www.atresplayer.com/registro?additionalData=eyJvcmlnaW5MYW5kaW5nIjoiaHR0cHM6Ly9zdXNjcmlwY2lvbi5hdHJlc3BsYXllci5jb20vaGF6dGUtcHJlbWl1bS9pbnRlcm5hY2lvbmFsL2V4cGF0LyIsInR5cGVPZkVudHJ5UG9pbnQiOiJsYW5kaW5nIiwicGFja2FnZUlkIjo4NzU1NjB9">contrata ahora</a>
      </article>
    </div>
  </section>
  <footer class="foo flex">
    <div class="wrapper cf">
      <p class="creds">© Atresmedia Corporación de Medios de Comunicación, S.A - A. Isla Graciosa 13, 28703, S.S. de los Reyes, Madrid. Reservados todos los derechos</p>
    </div>
  </footer>
`;
